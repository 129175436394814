@import '../../includes/all';

#errorContainer {
  display: none;
  text-align: left;

  > div {
    background-color: $warning-balloon-bg;
    display: inline-block;
    padding: 5px 10px;
    width: 100%;
    min-width: 130px;

    &:empty::before {
      // Add a space char for height
      content: '\00a0 ';
    }

    > div {
      &:not(:first-child) {
        margin-top: 5px;
      }

      > img {
        width: 16px;
        float: left;
      }

      > span {
        display: block;
        margin-left: 20px;
      }
    }
  }

  > img {
    display: block;
    width: 20px;
    position: relative;
    left: 50px;
  }
}
