@import '../../includes/all';

$red-text: #d0021b;

.hidden {
  display: none !important;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &.focusable {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
}

.invisible {
  visibility: hidden;
}

.clearfix {
  @include clearfix;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.red-text {
  color: $red-text !important;
}
