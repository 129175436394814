$xsmall: 320px;
$small: 641px;
$medium: 767px;
$large: 1025px;
$xlarge: 1281px;

$laptop: 1024px;
$tablet: 768px;
$mobile-l: 425px;
$mobile-m: 375px;
$mobile-s: 320px;

@mixin gt($width) {
  @media screen and (min-width: $width + 1) {
    @content;
  }
}

@mixin gte($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin st($width) {
  @media screen and (max-width: $width - 1) {
    @content;
  }
}

@mixin ste($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin between($width-min, $width-max) {
  @media screen and (min-width: $width-min + 1) and (max-width: $width-max - 1) {
    @content;
  }
}
