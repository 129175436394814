@import '../../includes/all';

$input-container-focused-mobile-bg: #f6f6f6;
$input-focused-mobile-bg: white;
$input-focused-mobile-border: lightgrey;
$input-border: lightgrey;
$placeholder-color: #4a4a4a;
$input-border-mobile: #9b9b9b;
$search-result-border: #e6e6e6;
$search-result-hover-bg: #eee;
$search-not-found-color: grey;
$search-border-top: #d9d9d9;

// TODO: Kill this monstrosity

select,
input:not([type='checkbox']) {
  &.invalid,
  &.valid {
    padding-top: 3px !important;
    border-bottom: 3px solid;
  }

  &.valid {
    border-bottom-color: $input-valid-border;
  }

  &.invalid {
    border-bottom-color: $input-invalid-border;
  }
}

.twitter-typeahead > input:first-child {
  display: none;
}

.tt-input {
  background-color: white !important;
  width: 125px;
}

.twitter-typeahead {
  width: 100%;
}

@include mobile {
  .inputDiv {
    margin: 20px 0;
  }
}

@include desktop {
  .form-flight,
  .form-noflight {
    width: 100%;
    background-color: white;

     > div {
      width: (100% / 3);
      display: inline-block;

      &.size-0 {
        display: none;
      }

      &.size-2 {
        width: 100% / 3 * 2;
      }
    }
  }
}

.time-seperator {
  color: $text-color-dark !important;
  font-size: 14px !important;
  position: absolute;
  top: 25%;
}

.form-flight {
  display: none;
}

.resultaten {
  position: absolute;
  background-color: #fff;
  z-index: 1000;
  border-top: 1px solid $search-border-top;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
  box-sizing: border-box;
  overflow: hidden;
  display: inline-block;
  left: 0;
  top: 40px;
  width: auto;
  min-width: 100%;
  max-width: 960px;

  @include mobile {
    max-width: 100%;
    top: 42px;
  }

  .zoekresultaat {
    cursor: default;
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid $search-result-border;
    font-size: 13px;

    &:hover:not(.not-found),
    &.selected {
      cursor: pointer;
      background-color: $search-result-hover-bg;
    }

    &.not-found,
    .not-found {
      color: $search-not-found-color !important;
    }
  }
}

.noflight-container {
  visibility: hidden;

   > * {
    vertical-align: middle;
  }

  input {
    margin-right: 5px;
    transform: scale(1.3);
  }
}

.noflight-hours,
.noflight-minutes {
  width: 50%;
}

#flightForm {
  margin-top: 10px;
  $input-padding: 9px;

  @include mobile {
    clear: both;
  }

  @include desktop {
    margin-top: 35px;
  }

  .inputDiv {
    position: relative;
    transition: background 1s;

     > .field-label {
      position: absolute;
      color: $text-color-light;
      font-size: 12px;

      &.utc-shown {
        display: none; /* Shown by JS */
      }

      @include mobile {
        left: 0;
        right: 0;
        bottom: 100%;
        background-color: rgba($theme-bg, .65);
        padding: 5px 7px;
      }

      @include desktop {
        font-size: 16px;
        left: 5px;
        top: -25px;
        font-weight: 600;
      }
    }

     > img {
      display: none;
    }

    @include mobile {
      box-shadow: 0 0 1px 0 rgba(0,0,0,.3);

      &.focused {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        margin: 0;
        background-color: $input-container-focused-mobile-bg;
        padding-bottom: 36px; // Height of back button

        .field-label {
          display: none;
        }

         > input {
          height: 50px;
          border-bottom: 1px solid $input-focused-mobile-border;
          background-color: $input-focused-mobile-bg;
        }

         > img {
          position: absolute;
          top: 15px;
          right: 12px;
          float: right;
          height: 18px;
        }

         > span {
          top: 7px;
        }

        .resultaten {
          top: 50px;
          box-shadow: none;

          &.maps {
            .zoekresultaat {
              margin-bottom: -10px;
            }

            .plaatsnaam {
              font-size: 14px;
            }

            .prediction-details {
              display: block;
              margin-left: 22px;
              position: relative;
              bottom: 10px;
            }
          }
        }
      }
    }
  }

  input:not([type='checkbox']),
  select,
  button {
    height: 42px;

    @include mobile {
      &:focus {
        outline: none;
      }
    }

    @include desktop {
      height: 40px;
      vertical-align: top;
    }
  }

  input,
  select {
    border: 0;
  }

  @include desktop {
    input,
    select:not(:first-of-type) {
      border-right: 1px solid $input-border;
    }
  }

  input:not([type='checkbox']),
  button {
    width: 100%;
  }

  select {
    text-indent: 1px;

    &::-ms-expand {
      display: none;
    }
  }

  button {
    font-size: 18px;
    position: relative;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

     > img {
      max-width: 25%;
      right: 3%;
    }

     > img {
      max-height: 70%;
      position: absolute;
      top: 6px;
    }
  }

   > div > div {
    padding: 10px 0;

    @include desktop {
      padding: 0;
    }
  }

  .splitContainer {
    position: relative;

     > div {
      width: (100% - 5) / 2;
      display: inline-block;

      &:first-child {
        margin-right: 5%;
      }

      @include desktop {
        width: 50%;

        &:first-child {
          margin-right: 0;
        }
      }

      @include mobile {
        &.inputDiv.focused {
          margin: 0;
          width: 50%;

          &:last-of-type {
            left: auto;
          }

           > span {
            display: inline-block !important;
          }

           > input {
            box-shadow: none;
          }
        }
      }
    }
  }

  input {
    ::-webkit-input-placeholder,
    :-moz-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder {
      color: $placeholder-color;
      font-size: 16px;
    }
  }

  .X {
    margin: 0 15px;
    height: 15px;
  }

  .SelectIcon {
    height: 40px;
  }

  @include desktop {
    position: relative;

    .opties {
      display: block;
      color: white;
      margin-top: 10px;
      text-align: left;

      input,
      span {
        vertical-align: middle;
      }

      input,
      img {
        margin-right: 3px;
      }

      a,
      label {
        color: white;
        cursor: pointer;
      }

      a {
        font-style: italic;
      }

      label {
        font-size: 11px;
      }

      span {
        display: inline-block;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }

    // Flexboxes
    #formDiv,
    #formDivReturn {
      //height: 88px;
      text-align: left;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;
      margin-right: 124px;

      #fromHomeDiv,
      #toHotelDiv,
      #fromHomeDivReturn,
      #toHotelDivReturn {
        order: 0;
        flex: 1 0 auto;
        align-self: auto;
        flex-basis: 0;
      }

      .splitContainer {
        order: 0;
        flex: 0 0 250px;
        align-self: auto;
      }
    }

    #fromHomeDiv,
    #toHotelDiv,
    #fromHomeDivReturn,
    #toHotelDivReturn,
    .inputDiv {
      //display: inline-block;
      vertical-align: top;
    }

    .isGoed,
    .isFout {
      padding-top: 3px !important;
    }

    .isGoed {
      border-bottom: 3px solid $input-valid-border;
    }

    .isFout {
      border-bottom: 3px solid $input-invalid-border;
    }

    #persons option::after {
      content: ' persons';
    }

    #buttons {
      width: 125px;
      position: absolute;
      top: 0;
      right: 0;

       > div {
        width: 100%;
      }

      .splitContainer > div:first-child {
        display: none;
      }

      .inputDiv {
        width: 100%;
        vertical-align: middle;
      }
    }
  }
}

#searchButton {
  transition: 1s padding-left;

  &:hover {
    padding-top: 1px !important;
  }

  img:first-of-type {
    display: none;
  }

  &.loading {
    @include desktop {
      padding-left: 17px !important;
    }

    text-align: left;

    img:last-child {
      display: none;
    }

    img:first-of-type {
      display: inline-block;
    }
  }
}

#noflightLinksContainer,
#noflightRechtsContainer,
#noflightLinksContainerReturn,
#noflightRechtsContainerReturn {
  &.noflight {
    max-width: none !important;
    width: 100% !important;

     > .noflightLinks,
    .noflightRechts,
    .noflightLinksReturn,
    .noflightRechtsReturn {
      width: 50%;
    }
  }
}

#noflight-pickup-date1,
#noflight-pickup-date2,
#noflight-pickup-date1Return,
#noflight-pickup-date2Return,
#noflight-pickup-hours1,
#noflight-pickup-hours2,
#noflight-pickup-hours1Return,
#noflight-pickup-hours2Return {
  border-right: 0 !important;
}

.selectContainer {
  position: relative;
  display: none !important; // TODO: Remove from HTML
}

@include desktop {
  #retour-gutter {
    height: 40px;
  }
}
