@import '../../includes/all';

$header-bg: white;

html.show-header header {
  display: block;
}

html.show-language-picker #language-switcher {
  display: inline-block;
}

header {
  height: 100px;
  background-color: $header-bg;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, .1);
  display: none;

   > div {
    height: 100%;
    margin: 0 auto;
    max-width: 990px;
  }
}

#logo {
  margin-left: 15px;
  display: inline-block;
  height: 100%;
  width: 160px;
  margin-left: 15px;
  background-image: url($logo);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @include ste($mobile-l) {
    width: 120px;
  }
}
