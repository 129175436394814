@import '../../includes/all';

#return-container {
  float: right;
  width: auto;
  padding: 0 !important;
  position: absolute;
  top: 54px;
  right: 0;
}

#returnCheckbox {
  vertical-align: middle;
  margin-right: 3px;
}

#return-btn {
  padding: 0 !important;
  width: 125px !important;
  transition: transform .4s linear;
  color: $btn-inactive-color;
  background-color: $btn-inactive-bg;
  border-bottom-color: $btn-inactive-border-color;

  &::before {
    content: '+ ';
  }

  &.active {
    transform: translateY(26px);

    &::before {
      content: '– ';
    }
  }

  &:hover {
    padding-top: 1px !important;
  }
}

.form-flight,
.form-noflight {
  background-color: transparent;
}

#noflight-leftReturn,
#noflight-leftReturn,
#noflight-middleReturn,
#noflight-middleReturn,
#fromHomeDivReturn,
#flight-rightReturn,
#trip-optionsReturn {
  visibility: hidden;
}
