@import '../includes/all';

//colors
$color_alto_approx: #ddd;
$black_20: rgba(0, 0, 0, .2);
$white: white;
$color_tapa_approx: #777;
$color_gallery_approx: #eee;
$color_steel_blue_approx: #337ab7;
$black_25: rgba(0, 0, 0, .25);
$color_celeste_approx: #ccc;
$black_15: rgba(0, 0, 0, .15);
$black_17_5: rgba(0, 0, 0, .175);
$yellow: #ff0;
$black: #000;
$silver: #c0c0c0;
$color_alabaster_approx: #f9f9f9;
$color_wild_sand_approx: #f5f5f5;
$color_gray_nurse_approx: #e8e8e8;
$color_willow_brook_approx: #dff0d8;
$color_tea_green_approx: #d0e9c6;
$color_link_water_approx: #d9edf7;
$color_tropical_blue_approx: #c4e3f3;
$color_solitaire_approx: #fcf8e3;
$color_pipi_approx: #faf2cc;
$color_vanilla_ice_approx: #f2dede;
$color_oyster_pink_approx: #ebcccc;

//fonts
$font_0: Glyphicons Halflings;
$font_1: sans-serif;
$font_2: monospace;

//urls
$url_0: url($asset-url + '/fonts/glyphicons/glyphicons-halflings-regular.eot');
$url_1: url($asset-url + '/fonts/glyphicons/glyphicons-halflings-regular.eot?#iefix');
$url_2: url($asset-url + '/fonts/glyphicons/glyphicons-halflings-regular.woff2');
$url_3: url($asset-url + '/fonts/glyphicons/glyphicons-halflings-regular.woff');
$url_4: url($asset-url + '/fonts/glyphicons/glyphicons-halflings-regular.ttf');
$url_5: url($asset-url + '/fonts/glyphicons/glyphicons-halflings-regular.svg#glyphicons_halflingsregular');

.bootstrap-datetimepicker-widget {
  list-style: none;
  &.dropdown-menu {
    margin: 2px 0;
    padding: 4px;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
    }
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
    }
    &.bottom {
      &:before {
        border-right: 7px solid transparent;
        border-bottom: 7px solid $black_20;
        border-left: 7px solid transparent;
        top: -7px;
        left: 7px;
      }
      &:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $white;
        top: -6px;
        left: 8px;
      }
    }
    &.top {
      &:before {
        border-top: 7px solid $black_20;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        bottom: -7px;
        left: 6px;
      }
      &:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $white;
        bottom: -6px;
        left: 7px;
      }
    }
    &.pull-right {
      &:before {
        left: auto;
        right: 6px;
      }
      &:after {
        left: auto;
        right: 7px;
      }
    }
  }
  .list-unstyled {
    margin: 0;
  }
  a[data-action] {
    padding: 6px 0;
    &:active {
      //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: none;
    }
  }
  .timepicker-hour {
    width: 54px;
    font-weight: bold;
    font-size: 1.2em;
    margin: 0;
  }
  .timepicker-minute {
    width: 54px;
    font-weight: bold;
    font-size: 1.2em;
    margin: 0;
  }
  .timepicker-second {
    width: 54px;
    font-weight: bold;
    font-size: 1.2em;
    margin: 0;
  }
  button[data-action] {
    padding: 6px;
  }
  .btn[data-action='incrementHours']::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: 'Increment Hours';
  }
  .btn[data-action='incrementMinutes']::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: 'Increment Minutes';
  }
  .btn[data-action='decrementHours']::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: 'Decrement Hours';
  }
  .btn[data-action='decrementMinutes']::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: 'Decrement Minutes';
  }
  .btn[data-action='showHours']::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: 'Show Hours';
  }
  .btn[data-action='showMinutes']::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: 'Show Minutes';
  }
  .btn[data-action='togglePeriod']::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: 'Toggle AM/PM';
  }
  .btn[data-action='clear']::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: 'Clear the picker';
  }
  .btn[data-action='today']::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: 'Set the date to today';
  }
  .picker-switch {
    text-align: center;
    td {
      padding: 0;
      margin: 0;
      height: auto;
      width: auto;
      line-height: inherit;
      span {
        line-height: 2.5;
        height: 2.5em;
        width: 100%;
      }
    }
  }
  .picker-switch::after {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    content: 'Toggle Date and Time Screens';
  }
  table {
    width: 100%;
    margin: 0;
    td {
      text-align: center;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 4px;
      height: 54px;
      line-height: 54px;
      width: 54px;
      &.cw {
        font-size: .8em;
        height: 20px;
        line-height: 20px;
        color: $color_tapa_approx;
      }
      &.day {
        height: 20px;
        line-height: 20px;
        width: 20px;
        &:hover {
          background: $color_gallery_approx;
          cursor: pointer;
        }
      }
      &.old {
        color: $color_tapa_approx;
      }
      &.new {
        color: $color_tapa_approx;
      }
      &.today {
        position: relative;
        &:before {
          content: '';
          display: inline-block;
          border: solid transparent;
          border-width: 0 0 7px 7px;
          border-bottom-color: $color_steel_blue_approx;
          border-top-color: $black_20;
          position: absolute;
          bottom: 4px;
          right: 4px;
        }
      }
      &.active {
        background-color: $color_steel_blue_approx;
        color: $white;
        //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        text-shadow: 0 -1px 0 $black_25;
        &:hover {
          background-color: $color_steel_blue_approx;
          color: $white;
          //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
          text-shadow: 0 -1px 0 $black_25;
        }
        &.today:before {
          border-bottom-color: $white;
        }
      }
      &.disabled {
        background: none;
        color: $color_tapa_approx;
        cursor: not-allowed;
        &:hover {
          background: none;
          color: $color_tapa_approx;
          cursor: not-allowed;
        }
      }
      span {
        display: inline-block;
        width: 54px;
        height: 54px;
        line-height: 54px;
        margin: 2px;
        cursor: pointer;
        //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
        border-radius: 4px;
        &:hover {
          background: $color_gallery_approx;
        }
        &.active {
          background-color: $color_steel_blue_approx;
          color: $white;
          //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
          text-shadow: 0 -1px 0 $black_25;
        }
        &.old {
          color: $color_tapa_approx;
        }
        &.disabled {
          background: none;
          color: $color_tapa_approx;
          cursor: not-allowed;
          &:hover {
            background: none;
            color: $color_tapa_approx;
            cursor: not-allowed;
          }
        }
      }
      &.hour:hover {
        background: $color_gallery_approx;
        cursor: pointer;
      }
      &.minute:hover {
        background: $color_gallery_approx;
        cursor: pointer;
      }
      &.second:hover {
        background: $color_gallery_approx;
        cursor: pointer;
      }
    }
    th {
      text-align: center;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 4px;
      height: 20px;
      line-height: 20px;
      width: 20px;
      &.picker-switch {
        width: 145px;
      }
      &.disabled {
        background: none;
        color: $color_tapa_approx;
        cursor: not-allowed;
        &:hover {
          background: none;
          color: $color_tapa_approx;
          cursor: not-allowed;
        }
      }
      &.prev::after {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
        content: 'Previous Month';
      }
      &.next::after {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
        content: 'Next Month';
      }
    }
    thead tr:first-child th {
      cursor: pointer;
      &:hover {
        background: $color_gallery_approx;
      }
    }
  }
  &.wider {
    width: 21em;
  }
  &.usetwentyfour td.hour {
    height: 27px;
    line-height: 27px;
  }
  .datepicker-decades .decade {
    line-height: 1.8em !important;
  }
}
@media(min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media(min-width: 992px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media(min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
@font-face {
  font-family: $font_0;
  src: $url_0;
  src: $url_1 format('embedded-opentype'), $url_2 format('woff2'), $url_3 format('woff'), $url_4 format('truetype'), $url_5 format('svg');
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  //Instead of the line below you could use @include user-select($select)
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 4px;
}
.collapse {
  display: none;
  &.in {
    display: block;
  }
}
.dropdown-menu {
  position: absolute;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: $white;
  //Instead of the line below you could use @include background-clip($clip)
  background-clip: padding-box;
  border: 1px solid $color_celeste_approx;
  border: 1px solid $black_15;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 4px;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 6px 12px $black_17_5;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
html {
  font-family: $font_1;
  font-size: 10px;
  input[type='button'] {
    cursor: pointer;
  }
  input[disabled] {
    cursor: default;
  }
}
article {
  display: block;
}
aside {
  display: block;
}
details {
  display: block;
}
figcaption {
  display: block;
}
figure {
  display: block;
  margin: 1em 40px;
  margin: 0;
}
footer {
  display: block;
}
hgroup {
  display: block;
}
main {
  display: block;
}
menu {
  display: block;
}
nav {
  display: block;
}
section {
  display: block;
}
summary {
  display: block;
}
audio {
  display: inline-block;
  vertical-align: baseline;
  &:not([controls]) {
    display: none;
    height: 0;
  }
}
canvas {
  display: inline-block;
  vertical-align: baseline;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
video {
  display: inline-block;
  vertical-align: baseline;
}
[hidden] {
  display: none;
}
template {
  display: none;
}
a {
  &:active {
    outline: 0;
  }
  &:hover {
    outline: 0;
  }
  &:focus {
    outline: thin dotted;
    outline-offset: -2px;
  }
}
abbr[title] {
  border-bottom: 1px dotted;
}
b {
  font-weight: bold;
}
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
mark {
  background: $yellow;
  color: $black;
}
small {
  font-size: 80%;
}
sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  bottom: -.25em;
}
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -.5em;
}
img {
  border: 0;
  vertical-align: middle;
}
hr {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: content-box;
  height: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid $color_gallery_approx;
}
pre {
  overflow: auto;
  font-family: $font_2, $font_2;
  font-size: 1em;
}
code {
  font-family: $font_2, $font_2;
  font-size: 1em;
}
kbd {
  font-family: $font_2, $font_2;
  font-size: 1em;
}
samp {
  font-family: $font_2, $font_2;
  font-size: 1em;
}
button {
  color: inherit;
  font: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input {
  color: inherit;
  font: inherit;
  margin: 0;
  line-height: normal;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
optgroup {
  color: inherit;
  font: inherit;
  margin: 0;
  font-weight: bold;
}
select {
  color: inherit;
  font: inherit;
  margin: 0;
  text-transform: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  overflow: auto;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input[type='reset'] {
  cursor: pointer;
}
input[type='submit'] {
  cursor: pointer;
}
button[disabled] {
  cursor: default;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type='checkbox'] {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  padding: 0;
}
input[type='radio'] {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  padding: 0;
}
input[type='number']::-webkit-inner-spin-button {
  height: auto;
}
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}
input[type='search'] {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: content-box;
}


fieldset {
  border: 1px solid $silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}
legend {
  border: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: transparent;
  col[class*='col-'] {
    position: static;
    float: none;
    display: table-column;
  }
  td[class*='col-'] {
    position: static;
    float: none;
    display: table-cell;
  }
  th[class*='col-'] {
    position: static;
    float: none;
    display: table-cell;
  }
}
td {
  padding: 0;
}
th {
  padding: 0;
  text-align: left;
}
@font-face {
  src: $url_0;
  src: $url_1 format('embedded-opentype'), $url_2 format('woff2'), $url_3 format('woff'), $url_4 format('truetype'), $url_5 format('svg');
}
* {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  &:before {
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
  }
  &:after {
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
  }
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.img-rounded {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 6px;
}
.img-thumbnail {
  padding: 4px;
  line-height: 1.42857143;
  background-color: $white;
  border: 1px solid $color_alto_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 4px;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all .2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-circle {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50%;
}
.sr-only-focusable {
  &:active {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}
[role='button'] {
  cursor: pointer;
}
caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: $color_tapa_approx;
  text-align: left;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
   > thead {
     > tr {
       > th {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 1px solid $color_alto_approx;
        vertical-align: bottom;
        border-bottom: 2px solid $color_alto_approx;
        &.active {
          background-color: $color_wild_sand_approx;
        }
        &.success {
          background-color: $color_willow_brook_approx;
        }
        &.info {
          background-color: $color_link_water_approx;
        }
        &.warning {
          background-color: $color_solitaire_approx;
        }
        &.danger {
          background-color: $color_vanilla_ice_approx;
        }
      }
       > td {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 1px solid $color_alto_approx;
        &.active {
          background-color: $color_wild_sand_approx;
        }
        &.success {
          background-color: $color_willow_brook_approx;
        }
        &.info {
          background-color: $color_link_water_approx;
        }
        &.warning {
          background-color: $color_solitaire_approx;
        }
        &.danger {
          background-color: $color_vanilla_ice_approx;
        }
      }
      &.active {
         > td {
          background-color: $color_wild_sand_approx;
        }
         > th {
          background-color: $color_wild_sand_approx;
        }
      }
      &.success {
         > td {
          background-color: $color_willow_brook_approx;
        }
         > th {
          background-color: $color_willow_brook_approx;
        }
      }
      &.info {
         > td {
          background-color: $color_link_water_approx;
        }
         > th {
          background-color: $color_link_water_approx;
        }
      }
      &.warning {
         > td {
          background-color: $color_solitaire_approx;
        }
         > th {
          background-color: $color_solitaire_approx;
        }
      }
      &.danger {
         > td {
          background-color: $color_vanilla_ice_approx;
        }
         > th {
          background-color: $color_vanilla_ice_approx;
        }
      }
    }
    &:first-child > tr:first-child {
       > th {
        border-top: 0;
      }
       > td {
        border-top: 0;
      }
    }
  }
   > tbody {
     > tr {
       > th {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 1px solid $color_alto_approx;
        &.active {
          background-color: $color_wild_sand_approx;
        }
        &.success {
          background-color: $color_willow_brook_approx;
        }
        &.info {
          background-color: $color_link_water_approx;
        }
        &.warning {
          background-color: $color_solitaire_approx;
        }
        &.danger {
          background-color: $color_vanilla_ice_approx;
        }
      }
       > td {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 1px solid $color_alto_approx;
        &.active {
          background-color: $color_wild_sand_approx;
        }
        &.success {
          background-color: $color_willow_brook_approx;
        }
        &.info {
          background-color: $color_link_water_approx;
        }
        &.warning {
          background-color: $color_solitaire_approx;
        }
        &.danger {
          background-color: $color_vanilla_ice_approx;
        }
      }
      &.active {
         > td {
          background-color: $color_wild_sand_approx;
        }
         > th {
          background-color: $color_wild_sand_approx;
        }
      }
      &.success {
         > td {
          background-color: $color_willow_brook_approx;
        }
         > th {
          background-color: $color_willow_brook_approx;
        }
      }
      &.info {
         > td {
          background-color: $color_link_water_approx;
        }
         > th {
          background-color: $color_link_water_approx;
        }
      }
      &.warning {
         > td {
          background-color: $color_solitaire_approx;
        }
         > th {
          background-color: $color_solitaire_approx;
        }
      }
      &.danger {
         > td {
          background-color: $color_vanilla_ice_approx;
        }
         > th {
          background-color: $color_vanilla_ice_approx;
        }
      }
    }
     + tbody {
      border-top: 2px solid $color_alto_approx;
    }
  }
  .table {
    background-color: $white;
  }
   > tfoot > tr {
     > th {
      padding: 8px;
      line-height: 1.42857143;
      vertical-align: top;
      border-top: 1px solid $color_alto_approx;
      &.active {
        background-color: $color_wild_sand_approx;
      }
      &.success {
        background-color: $color_willow_brook_approx;
      }
      &.info {
        background-color: $color_link_water_approx;
      }
      &.warning {
        background-color: $color_solitaire_approx;
      }
      &.danger {
        background-color: $color_vanilla_ice_approx;
      }
    }
     > td {
      padding: 8px;
      line-height: 1.42857143;
      vertical-align: top;
      border-top: 1px solid $color_alto_approx;
      &.active {
        background-color: $color_wild_sand_approx;
      }
      &.success {
        background-color: $color_willow_brook_approx;
      }
      &.info {
        background-color: $color_link_water_approx;
      }
      &.warning {
        background-color: $color_solitaire_approx;
      }
      &.danger {
        background-color: $color_vanilla_ice_approx;
      }
    }
    &.active {
       > td {
        background-color: $color_wild_sand_approx;
      }
       > th {
        background-color: $color_wild_sand_approx;
      }
    }
    &.success {
       > td {
        background-color: $color_willow_brook_approx;
      }
       > th {
        background-color: $color_willow_brook_approx;
      }
    }
    &.info {
       > td {
        background-color: $color_link_water_approx;
      }
       > th {
        background-color: $color_link_water_approx;
      }
    }
    &.warning {
       > td {
        background-color: $color_solitaire_approx;
      }
       > th {
        background-color: $color_solitaire_approx;
      }
    }
    &.danger {
       > td {
        background-color: $color_vanilla_ice_approx;
      }
       > th {
        background-color: $color_vanilla_ice_approx;
      }
    }
  }
   > caption + thead > tr:first-child {
     > th {
      border-top: 0;
    }
     > td {
      border-top: 0;
    }
  }
   > colgroup + thead > tr:first-child {
     > th {
      border-top: 0;
    }
     > td {
      border-top: 0;
    }
  }
}
.table-condensed {
   > thead > tr {
     > th {
      padding: 5px;
    }
     > td {
      padding: 5px;
    }
  }
   > tbody > tr {
     > th {
      padding: 5px;
    }
     > td {
      padding: 5px;
    }
  }
   > tfoot > tr {
     > th {
      padding: 5px;
    }
     > td {
      padding: 5px;
    }
  }
}
.table-bordered {
  border: 1px solid $color_alto_approx;
   > thead > tr {
     > th {
      border: 1px solid $color_alto_approx;
      border-bottom-width: 2px;
    }
     > td {
      border: 1px solid $color_alto_approx;
      border-bottom-width: 2px;
    }
  }
   > tbody > tr {
     > th {
      border: 1px solid $color_alto_approx;
    }
     > td {
      border: 1px solid $color_alto_approx;
    }
  }
   > tfoot > tr {
     > th {
      border: 1px solid $color_alto_approx;
    }
     > td {
      border: 1px solid $color_alto_approx;
    }
  }
}
.table-responsive {
  overflow-x: auto;
  min-height: .01%;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    border: 1px solid $color_alto_approx;
     >  {
      .table {
        margin-bottom: 0;
         > thead > tr {
           > th {
            white-space: nowrap;
          }
           > td {
            white-space: nowrap;
          }
        }
         > tbody > tr {
           > th {
            white-space: nowrap;
          }
           > td {
            white-space: nowrap;
          }
        }
         > tfoot > tr {
           > th {
            white-space: nowrap;
          }
           > td {
            white-space: nowrap;
          }
        }
      }
      .table-bordered {
        border: 0;
         > thead > tr {
           > th {
            &:first-child {
              border-left: 0;
            }
            &:last-child {
              border-right: 0;
            }
          }
           > td {
            &:first-child {
              border-left: 0;
            }
            &:last-child {
              border-right: 0;
            }
          }
        }
         > tbody > tr {
           > th {
            &:first-child {
              border-left: 0;
            }
            &:last-child {
              border-right: 0;
            }
          }
           > td {
            &:first-child {
              border-left: 0;
            }
            &:last-child {
              border-right: 0;
            }
          }
          &:last-child {
             > th {
              border-bottom: 0;
            }
             > td {
              border-bottom: 0;
            }
          }
        }
         > tfoot > tr {
           > th {
            &:first-child {
              border-left: 0;
            }
            &:last-child {
              border-right: 0;
            }
          }
           > td {
            &:first-child {
              border-left: 0;
            }
            &:last-child {
              border-right: 0;
            }
          }
          &:last-child {
             > th {
              border-bottom: 0;
            }
             > td {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}
.fade {
  opacity: 0;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: opacity .15s linear;
  &.in {
    opacity: 1;
  }
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  //Instead of the line below you could use @include transition-property($property-1, $property-2, $property-3, $property-4, $property-5, $property-6, $property-7, $property-8, $property-9, $property-10)
  transition-property: height, visibility;
  //Instead of the line below you could use @include transition-duration($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10)
  transition-duration: .35s;
  //Instead of the line below you could use @include transition-timing-function($function-1, $function-2, $function-3, $function-4, $function-5, $function-6, $function-7, $function-8, $function-9, $function-10)
  transition-timing-function: ease;
}
.clearfix {
  &:before {
    content: ' ';
    display: table;
  }
  &:after {
    content: ' ';
    display: table;
    clear: both;
  }
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.glyphicon-time:before {
  content: '\e023';
}
.glyphicon-chevron-left:before {
  content: '\e079';
}
.glyphicon-chevron-right:before {
  content: '\e080';
}
.glyphicon-chevron-up:before {
  content: '\e113';
}
.glyphicon-chevron-down:before {
  content: '\e114';
}
.glyphicon-calendar:before {
  content: '\e109';
}
svg:not(:root) {
  overflow: hidden;
}
.glyphicon-asterisk:before {
  content: '\002a';
}
.glyphicon-plus:before {
  content: '\002b';
}
.glyphicon-euro:before {
  content: '\20ac';
}
.glyphicon-eur:before {
  content: '\20ac';
}
.glyphicon-minus:before {
  content: '\2212';
}
.glyphicon-cloud:before {
  content: '\2601';
}
.glyphicon-envelope:before {
  content: '\2709';
}
.glyphicon-pencil:before {
  content: '\270f';
}
.glyphicon-glass:before {
  content: '\e001';
}
.glyphicon-music:before {
  content: '\e002';
}
.glyphicon-search:before {
  content: '\e003';
}
.glyphicon-heart:before {
  content: '\e005';
}
.glyphicon-star:before {
  content: '\e006';
}
.glyphicon-star-empty:before {
  content: '\e007';
}
.glyphicon-user:before {
  content: '\e008';
}
.glyphicon-film:before {
  content: '\e009';
}
.glyphicon-th-large:before {
  content: '\e010';
}
.glyphicon-th:before {
  content: '\e011';
}
.glyphicon-th-list:before {
  content: '\e012';
}
.glyphicon-ok:before {
  content: '\e013';
}
.glyphicon-remove:before {
  content: '\e014';
}
.glyphicon-zoom-in:before {
  content: '\e015';
}
.glyphicon-zoom-out:before {
  content: '\e016';
}
.glyphicon-off:before {
  content: '\e017';
}
.glyphicon-signal:before {
  content: '\e018';
}
.glyphicon-cog:before {
  content: '\e019';
}
.glyphicon-trash:before {
  content: '\e020';
}
.glyphicon-home:before {
  content: '\e021';
}
.glyphicon-file:before {
  content: '\e022';
}
.glyphicon-road:before {
  content: '\e024';
}
.glyphicon-download-alt:before {
  content: '\e025';
}
.glyphicon-download:before {
  content: '\e026';
}
.glyphicon-upload:before {
  content: '\e027';
}
.glyphicon-inbox:before {
  content: '\e028';
}
.glyphicon-play-circle:before {
  content: '\e029';
}
.glyphicon-repeat:before {
  content: '\e030';
}
.glyphicon-refresh:before {
  content: '\e031';
}
.glyphicon-list-alt:before {
  content: '\e032';
}
.glyphicon-lock:before {
  content: '\e033';
}
.glyphicon-flag:before {
  content: '\e034';
}
.glyphicon-headphones:before {
  content: '\e035';
}
.glyphicon-volume-off:before {
  content: '\e036';
}
.glyphicon-volume-down:before {
  content: '\e037';
}
.glyphicon-volume-up:before {
  content: '\e038';
}
.glyphicon-qrcode:before {
  content: '\e039';
}
.glyphicon-barcode:before {
  content: '\e040';
}
.glyphicon-tag:before {
  content: '\e041';
}
.glyphicon-tags:before {
  content: '\e042';
}
.glyphicon-book:before {
  content: '\e043';
}
.glyphicon-bookmark:before {
  content: '\e044';
}
.glyphicon-print:before {
  content: '\e045';
}
.glyphicon-camera:before {
  content: '\e046';
}
.glyphicon-font:before {
  content: '\e047';
}
.glyphicon-bold:before {
  content: '\e048';
}
.glyphicon-italic:before {
  content: '\e049';
}
.glyphicon-text-height:before {
  content: '\e050';
}
.glyphicon-text-width:before {
  content: '\e051';
}
.glyphicon-align-left:before {
  content: '\e052';
}
.glyphicon-align-center:before {
  content: '\e053';
}
.glyphicon-align-right:before {
  content: '\e054';
}
.glyphicon-align-justify:before {
  content: '\e055';
}
.glyphicon-list:before {
  content: '\e056';
}
.glyphicon-indent-left:before {
  content: '\e057';
}
.glyphicon-indent-right:before {
  content: '\e058';
}
.glyphicon-facetime-video:before {
  content: '\e059';
}
.glyphicon-picture:before {
  content: '\e060';
}
.glyphicon-map-marker:before {
  content: '\e062';
}
.glyphicon-adjust:before {
  content: '\e063';
}
.glyphicon-tint:before {
  content: '\e064';
}
.glyphicon-edit:before {
  content: '\e065';
}
.glyphicon-share:before {
  content: '\e066';
}
.glyphicon-check:before {
  content: '\e067';
}
.glyphicon-move:before {
  content: '\e068';
}
.glyphicon-step-backward:before {
  content: '\e069';
}
.glyphicon-fast-backward:before {
  content: '\e070';
}
.glyphicon-backward:before {
  content: '\e071';
}
.glyphicon-play:before {
  content: '\e072';
}
.glyphicon-pause:before {
  content: '\e073';
}
.glyphicon-stop:before {
  content: '\e074';
}
.glyphicon-forward:before {
  content: '\e075';
}
.glyphicon-fast-forward:before {
  content: '\e076';
}
.glyphicon-step-forward:before {
  content: '\e077';
}
.glyphicon-eject:before {
  content: '\e078';
}
.glyphicon-plus-sign:before {
  content: '\e081';
}
.glyphicon-minus-sign:before {
  content: '\e082';
}
.glyphicon-remove-sign:before {
  content: '\e083';
}
.glyphicon-ok-sign:before {
  content: '\e084';
}
.glyphicon-question-sign:before {
  content: '\e085';
}
.glyphicon-info-sign:before {
  content: '\e086';
}
.glyphicon-screenshot:before {
  content: '\e087';
}
.glyphicon-remove-circle:before {
  content: '\e088';
}
.glyphicon-ok-circle:before {
  content: '\e089';
}
.glyphicon-ban-circle:before {
  content: '\e090';
}
.glyphicon-arrow-left:before {
  content: '\e091';
}
.glyphicon-arrow-right:before {
  content: '\e092';
}
.glyphicon-arrow-up:before {
  content: '\e093';
}
.glyphicon-arrow-down:before {
  content: '\e094';
}
.glyphicon-share-alt:before {
  content: '\e095';
}
.glyphicon-resize-full:before {
  content: '\e096';
}
.glyphicon-resize-small:before {
  content: '\e097';
}
.glyphicon-exclamation-sign:before {
  content: '\e101';
}
.glyphicon-gift:before {
  content: '\e102';
}
.glyphicon-leaf:before {
  content: '\e103';
}
.glyphicon-fire:before {
  content: '\e104';
}
.glyphicon-eye-open:before {
  content: '\e105';
}
.glyphicon-eye-close:before {
  content: '\e106';
}
.glyphicon-warning-sign:before {
  content: '\e107';
}
.glyphicon-plane:before {
  content: '\e108';
}
.glyphicon-random:before {
  content: '\e110';
}
.glyphicon-comment:before {
  content: '\e111';
}
.glyphicon-magnet:before {
  content: '\e112';
}
.glyphicon-retweet:before {
  content: '\e115';
}
.glyphicon-shopping-cart:before {
  content: '\e116';
}
.glyphicon-folder-close:before {
  content: '\e117';
}
.glyphicon-folder-open:before {
  content: '\e118';
}
.glyphicon-resize-vertical:before {
  content: '\e119';
}
.glyphicon-resize-horizontal:before {
  content: '\e120';
}
.glyphicon-hdd:before {
  content: '\e121';
}
.glyphicon-bullhorn:before {
  content: '\e122';
}
.glyphicon-bell:before {
  content: '\e123';
}
.glyphicon-certificate:before {
  content: '\e124';
}
.glyphicon-thumbs-up:before {
  content: '\e125';
}
.glyphicon-thumbs-down:before {
  content: '\e126';
}
.glyphicon-hand-right:before {
  content: '\e127';
}
.glyphicon-hand-left:before {
  content: '\e128';
}
.glyphicon-hand-up:before {
  content: '\e129';
}
.glyphicon-hand-down:before {
  content: '\e130';
}
.glyphicon-circle-arrow-right:before {
  content: '\e131';
}
.glyphicon-circle-arrow-left:before {
  content: '\e132';
}
.glyphicon-circle-arrow-up:before {
  content: '\e133';
}
.glyphicon-circle-arrow-down:before {
  content: '\e134';
}
.glyphicon-globe:before {
  content: '\e135';
}
.glyphicon-wrench:before {
  content: '\e136';
}
.glyphicon-tasks:before {
  content: '\e137';
}
.glyphicon-filter:before {
  content: '\e138';
}
.glyphicon-briefcase:before {
  content: '\e139';
}
.glyphicon-fullscreen:before {
  content: '\e140';
}
.glyphicon-dashboard:before {
  content: '\e141';
}
.glyphicon-paperclip:before {
  content: '\e142';
}
.glyphicon-heart-empty:before {
  content: '\e143';
}
.glyphicon-link:before {
  content: '\e144';
}
.glyphicon-phone:before {
  content: '\e145';
}
.glyphicon-pushpin:before {
  content: '\e146';
}
.glyphicon-usd:before {
  content: '\e148';
}
.glyphicon-gbp:before {
  content: '\e149';
}
.glyphicon-sort:before {
  content: '\e150';
}
.glyphicon-sort-by-alphabet:before {
  content: '\e151';
}
.glyphicon-sort-by-alphabet-alt:before {
  content: '\e152';
}
.glyphicon-sort-by-order:before {
  content: '\e153';
}
.glyphicon-sort-by-order-alt:before {
  content: '\e154';
}
.glyphicon-sort-by-attributes:before {
  content: '\e155';
}
.glyphicon-sort-by-attributes-alt:before {
  content: '\e156';
}
.glyphicon-unchecked:before {
  content: '\e157';
}
.glyphicon-expand:before {
  content: '\e158';
}
.glyphicon-collapse-down:before {
  content: '\e159';
}
.glyphicon-collapse-up:before {
  content: '\e160';
}
.glyphicon-log-in:before {
  content: '\e161';
}
.glyphicon-flash:before {
  content: '\e162';
}
.glyphicon-log-out:before {
  content: '\e163';
}
.glyphicon-new-window:before {
  content: '\e164';
}
.glyphicon-record:before {
  content: '\e165';
}
.glyphicon-save:before {
  content: '\e166';
}
.glyphicon-open:before {
  content: '\e167';
}
.glyphicon-saved:before {
  content: '\e168';
}
.glyphicon-import:before {
  content: '\e169';
}
.glyphicon-export:before {
  content: '\e170';
}
.glyphicon-send:before {
  content: '\e171';
}
.glyphicon-floppy-disk:before {
  content: '\e172';
}
.glyphicon-floppy-saved:before {
  content: '\e173';
}
.glyphicon-floppy-remove:before {
  content: '\e174';
}
.glyphicon-floppy-save:before {
  content: '\e175';
}
.glyphicon-floppy-open:before {
  content: '\e176';
}
.glyphicon-credit-card:before {
  content: '\e177';
}
.glyphicon-transfer:before {
  content: '\e178';
}
.glyphicon-cutlery:before {
  content: '\e179';
}
.glyphicon-header:before {
  content: '\e180';
}
.glyphicon-compressed:before {
  content: '\e181';
}
.glyphicon-earphone:before {
  content: '\e182';
}
.glyphicon-phone-alt:before {
  content: '\e183';
}
.glyphicon-tower:before {
  content: '\e184';
}
.glyphicon-stats:before {
  content: '\e185';
}
.glyphicon-sd-video:before {
  content: '\e186';
}
.glyphicon-hd-video:before {
  content: '\e187';
}
.glyphicon-subtitles:before {
  content: '\e188';
}
.glyphicon-sound-stereo:before {
  content: '\e189';
}
.glyphicon-sound-dolby:before {
  content: '\e190';
}
.glyphicon-sound-5-1:before {
  content: '\e191';
}
.glyphicon-sound-6-1:before {
  content: '\e192';
}
.glyphicon-sound-7-1:before {
  content: '\e193';
}
.glyphicon-copyright-mark:before {
  content: '\e194';
}
.glyphicon-registration-mark:before {
  content: '\e195';
}
.glyphicon-cloud-download:before {
  content: '\e197';
}
.glyphicon-cloud-upload:before {
  content: '\e198';
}
.glyphicon-tree-conifer:before {
  content: '\e199';
}
.glyphicon-tree-deciduous:before {
  content: '\e200';
}
.glyphicon-cd:before {
  content: '\e201';
}
.glyphicon-save-file:before {
  content: '\e202';
}
.glyphicon-open-file:before {
  content: '\e203';
}
.glyphicon-level-up:before {
  content: '\e204';
}
.glyphicon-copy:before {
  content: '\e205';
}
.glyphicon-paste:before {
  content: '\e206';
}
.glyphicon-alert:before {
  content: '\e209';
}
.glyphicon-equalizer:before {
  content: '\e210';
}
.glyphicon-king:before {
  content: '\e211';
}
.glyphicon-queen:before {
  content: '\e212';
}
.glyphicon-pawn:before {
  content: '\e213';
}
.glyphicon-bishop:before {
  content: '\e214';
}
.glyphicon-knight:before {
  content: '\e215';
}
.glyphicon-baby-formula:before {
  content: '\e216';
}
.glyphicon-tent:before {
  content: '\26fa';
}
.glyphicon-blackboard:before {
  content: '\e218';
}
.glyphicon-bed:before {
  content: '\e219';
}
.glyphicon-apple:before {
  content: '\f8ff';
}
.glyphicon-erase:before {
  content: '\e221';
}
.glyphicon-hourglass:before {
  content: '\231b';
}
.glyphicon-lamp:before {
  content: '\e223';
}
.glyphicon-duplicate:before {
  content: '\e224';
}
.glyphicon-piggy-bank:before {
  content: '\e225';
}
.glyphicon-scissors:before {
  content: '\e226';
}
.glyphicon-bitcoin:before {
  content: '\e227';
}
.glyphicon-btc:before {
  content: '\e227';
}
.glyphicon-xbt:before {
  content: '\e227';
}
.glyphicon-yen:before {
  content: '\00a5';
}
.glyphicon-jpy:before {
  content: '\00a5';
}
.glyphicon-ruble:before {
  content: '\20bd';
}
.glyphicon-rub:before {
  content: '\20bd';
}
.glyphicon-scale:before {
  content: '\e230';
}
.glyphicon-ice-lolly:before {
  content: '\e231';
}
.glyphicon-ice-lolly-tasted:before {
  content: '\e232';
}
.glyphicon-education:before {
  content: '\e233';
}
.glyphicon-option-horizontal:before {
  content: '\e234';
}
.glyphicon-option-vertical:before {
  content: '\e235';
}
.glyphicon-menu-hamburger:before {
  content: '\e236';
}
.glyphicon-modal-window:before {
  content: '\e237';
}
.glyphicon-oil:before {
  content: '\e238';
}
.glyphicon-grain:before {
  content: '\e239';
}
.glyphicon-sunglasses:before {
  content: '\e240';
}
.glyphicon-text-size:before {
  content: '\e241';
}
.glyphicon-text-color:before {
  content: '\e242';
}
.glyphicon-text-background:before {
  content: '\e243';
}
.glyphicon-object-align-top:before {
  content: '\e244';
}
.glyphicon-object-align-bottom:before {
  content: '\e245';
}
.glyphicon-object-align-horizontal:before {
  content: '\e246';
}
.glyphicon-object-align-left:before {
  content: '\e247';
}
.glyphicon-object-align-vertical:before {
  content: '\e248';
}
.glyphicon-object-align-right:before {
  content: '\e249';
}
.glyphicon-triangle-right:before {
  content: '\e250';
}
.glyphicon-triangle-left:before {
  content: '\e251';
}
.glyphicon-triangle-bottom:before {
  content: '\e252';
}
.glyphicon-triangle-top:before {
  content: '\e253';
}
.glyphicon-console:before {
  content: '\e254';
}
.glyphicon-superscript:before {
  content: '\e255';
}
.glyphicon-subscript:before {
  content: '\e256';
}
.glyphicon-menu-left:before {
  content: '\e257';
}
.glyphicon-menu-right:before {
  content: '\e258';
}
.glyphicon-menu-down:before {
  content: '\e259';
}
.glyphicon-menu-up:before {
  content: '\e260';
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: $color_alabaster_approx;
}
.table-hover > tbody > tr {
  &:hover {
    background-color: $color_wild_sand_approx;
     >  {
      .active {
        background-color: $color_gray_nurse_approx;
      }
      .success {
        background-color: $color_tea_green_approx;
      }
      .info {
        background-color: $color_tropical_blue_approx;
      }
      .warning {
        background-color: $color_pipi_approx;
      }
      .danger {
        background-color: $color_oyster_pink_approx;
      }
    }
  }
   > td {
    &.active:hover {
      background-color: $color_gray_nurse_approx;
    }
    &.success:hover {
      background-color: $color_tea_green_approx;
    }
    &.info:hover {
      background-color: $color_tropical_blue_approx;
    }
    &.warning:hover {
      background-color: $color_pipi_approx;
    }
    &.danger:hover {
      background-color: $color_oyster_pink_approx;
    }
  }
   > th {
    &.active:hover {
      background-color: $color_gray_nurse_approx;
    }
    &.success:hover {
      background-color: $color_tea_green_approx;
    }
    &.info:hover {
      background-color: $color_tropical_blue_approx;
    }
    &.warning:hover {
      background-color: $color_pipi_approx;
    }
    &.danger:hover {
      background-color: $color_oyster_pink_approx;
    }
  }
  &.active:hover {
     > td {
      background-color: $color_gray_nurse_approx;
    }
     > th {
      background-color: $color_gray_nurse_approx;
    }
  }
  &.success:hover {
     > td {
      background-color: $color_tea_green_approx;
    }
     > th {
      background-color: $color_tea_green_approx;
    }
  }
  &.info:hover {
     > td {
      background-color: $color_tropical_blue_approx;
    }
     > th {
      background-color: $color_tropical_blue_approx;
    }
  }
  &.warning:hover {
     > td {
      background-color: $color_pipi_approx;
    }
     > th {
      background-color: $color_pipi_approx;
    }
  }
  &.danger:hover {
     > td {
      background-color: $color_oyster_pink_approx;
    }
     > th {
      background-color: $color_oyster_pink_approx;
    }
  }
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
