@import '../includes/all';
@import '../../vendor/bootstrap-datetimepicker';

.bootstrap-datetimepicker-widget {
  border-radius: 0 !important;
  display: block;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: $theme-bg !important;
  border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td.today:not(.active)::before {
  border-bottom-color: $theme-bg !important;
}

.bootstrap-datetimepicker-widget.dropdown-menu.top::after {
  bottom: -5px;
}
