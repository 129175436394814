@import 'media';

@mixin clearfix {
  &::before {
    content: ' ';
    display: table;
  }

  &::after {
    content: ' ';
    display: table;
    clear: both;
  }
}

@mixin boxshadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
}

@mixin font-regular {
  font-family: 'Open Sans', 'Lato', sans-serif;
  font-weight: 400;
}

@mixin font-semibold {
  font-weight: 600;
}

@mixin font-bold {
  font-weight: 800;
}

@mixin font-light {
  font-weight: 300;
}

@mixin phone-s {
  @media only screen and (max-width: 320px) {
    @content;
  }
}

@mixin phone-m {
  @media only screen and (max-width: 375px) {
    @content;
  }
}

@mixin phone-l {
  @media only screen and (max-width: 425px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-device-width: $medium) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-device-width: $medium) {
    @content;
  }
}
