@import '../../includes/all';

$stopover-bg: #e6e6e6;
$stopover-mobile-border: #e4e5e6;

.stopover-container {
  display: none;
  background-color: $stopover-bg;
  padding: 5px;
  margin-bottom: 5px;

  @include desktop {
    border-radius: 5px;
    margin-top: 5px;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, .2);

    &.loaded {
      display: inline-block;
    }
  }

  @include mobile {
    border-bottom: 1px solid $stopover-mobile-border;

    &:not(.focused) {
      @media screen and (max-height: 610px) {
        display: none !important;
      }
    }

    &:not(.collapsed) {
      height: 100%;
    }
  }
}

#back-btn-focused {
  display: none;
  border: 0;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  text-transform: uppercase;
  padding: 10px;
  height: auto;
  font-size: 16px;
  z-index: 101;
}

.stopover-message {
  margin-bottom: 10px;
  padding: 5px 5px 0;
}

.stopover-origin {
  @include desktop {
    margin-right: 20px;
  }

  @include mobile {
    margin-bottom: 10px;
  }
}

.stopover-origin,
.stopover-destination {
  vertical-align: top;
  position: relative;
  padding-left: 35px;

   > img {
    width: 25px;
    position: absolute;
    left: 5px;
    top: 2px;
  }

  @include desktop {
    display: inline-block;
  }
}

.flight-list > div > button {
  @include mobile {
    width: 100%;
    height: 35px;
  }

  color: $theme-text;
  background-color: white;
  font-size: 12px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid white;
  position: relative;

   > img {
    // Deselect-icon
    display: none;
    height: 15px;
    margin-left: 5px;
  }

  &:focus {
    outline: none;
  }

  &:not(.no-margin) {
    margin-bottom: 10px;
  }

  &:not(.unchangable):hover {
    border-color: $theme-text;
  }

  &.unchangable {
    cursor: default;
  }

  &.selected {
    color: $text-color-dark;

    &:not(.unchangable) {
      &:hover {
        border-color: $input-disabled-color;
      }

       > img {
        @include mobile {
          height: 1em;
          position: absolute;
          right: 10px;
          bottom: 11px;
        }

        display: inline-block;
        height: .9em;
        position: relative;
        bottom: 1px;
      }
    }
  }

  &.disabled {
    color: $input-disabled-color;
    cursor: not-allowed;

    &:hover {
      border-color: $text-color-light;
    }
  }
}
