$header-selects-border: #e6e6e6;
$header-selects-bg: white;
$header-selects-hover-bg: #f4f4f4;

%showing {
  outline: none;

  > a,
  > span {
    display: block !important;

    &:not(:last-child) {
      border-bottom: 0;
    }

    &:not(:first-child) {
      border-top: 0;
    }
  }
}

#header-selects {
  float: right;
  height: 100%;
  padding: 32px 15px;
}

#gete-currency-switcher {
  display: inline-block;

  > span {
    font-size: 19px;
    text-align: center;
    padding-top: 3px;
  }

  &:focus {
    @extend %showing;
  }
}

#language-switcher {
  display: none;

  > span,
  > a {
    overflow: hidden;
    width: 100%;
    padding: 5px;

    .flag {
      width: 31px;
    }
  }

  &:hover {
    @extend %showing;
  }
}

#language-switcher,
#gete-currency-switcher {
  margin-left: 5px;
  height: 35px;
  vertical-align: middle;
  z-index: 100;
  position: relative;

  > span:hover {
    cursor: default;
  }

  > a,
  > span {
    display: block;
    position: relative;
    border: 1px solid $header-selects-border;
    height: 100%;
    background: $header-selects-bg no-repeat center;
    background-size: 30px;

    &:not(:first-child):hover {
      background-color: $header-selects-hover-bg;
      cursor: pointer;
    }

    &:not(:first-child) {
      display: none;
    }

    > span {
      color: black;
      display: block;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
