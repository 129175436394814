$theme-bg: #3c7dfa;
$destination1-img: 'https://cdn.themes.get-e.com/self.get-e.com/images/MK1Tt6uGg8gEmeT727cnpFksFtmgDpWMEBeKYC3j.jpeg';
$destination2-img: 'https://cdn.themes.get-e.com/self.get-e.com/images/9ulI73oSbtOLwRXfUm02g1FipxrO8mIo6cCotRJs.jpeg';
$destination3-img: 'https://cdn.themes.get-e.com/self.get-e.com/images/3LyibjUIYiB6dNkJtIRU5xutFBK3wSzAG3y9U9RZ.jpeg';
$clear-disabled-field-svg: "data:image/svg+xml,%3Csvg width='22' height='24' viewBox='0 0 22 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EX%3C/title%3E%3Cpath d='M11.005 11.273L3.185 2.41 2.824 2 2 2.726l.363.412 7.91 8.965-7.91 8.965L2 21.48l.823.726.363-.412 7.82-8.862 7.82 8.862.362.412.823-.726-.362-.412-7.91-8.965 7.91-8.965.363-.412L19.19 2l-.363.41-7.82 8.863z' stroke-width='2' stroke='%239b9b9b' fill='none' fill-rule='evenodd' stroke-linecap='square'/%3E%3C/svg%3E";
$icon-deselect-passenger-svg: "data:image/svg+xml,%3Csvg width='22' height='24' viewBox='0 0 22 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EX%3C/title%3E%3Cpath d='M11.005 11.273L3.185 2.41 2.824 2 2 2.726l.363.412 7.91 8.965-7.91 8.965L2 21.48l.823.726.363-.412 7.82-8.862 7.82 8.862.362.412.823-.726-.362-.412-7.91-8.965 7.91-8.965.363-.412L19.19 2l-.363.41-7.82 8.863z' stroke-width='2' stroke='%23094180' fill='none' fill-rule='evenodd' stroke-linecap='square'/%3E%3C/svg%3E";
$icon-info-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%233c7dfa' fill-rule='evenodd' d='M12 24C5.37258 24 0 18.62742 0 12S5.37258 0 12 0s12 5.37258 12 12-5.37258 12-12 12zM10.43216 2.70138v2.86815h3.1837V2.70138h-3.1837zm-1.3951 4.68254v.7933h.00372c.7081 0 1.29644.57865 1.29445 1.29245l-.0207 7.4586c-.00197.70907-.50564 1.29024-1.12496 1.2875v.78024h5.702v-.76316c-.58918 0-1.17837-.5816-1.17837-1.29906V7.2832s-2.90038-.01022-3.37438 0c-.474.0102-1.30177.10072-1.30177.10072z'/%3E%3C/svg%3E";
$icon-minus-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%233c7dfa' fill-rule='evenodd' d='M12 24C5.37258 24 0 18.62742 0 12S5.37258 0 12 0s12 5.37258 12 12-5.37258 12-12 12zM4.5 10.5v3h15v-3h-15z'/%3E%3C/svg%3E";
$icon-plus-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%233c7dfa' fill-rule='evenodd' d='M10.5 10.5h-6v3h6v6h3v-6h6v-3h-6v-6h-3v6zM12 24C5.37258 24 0 18.62742 0 12S5.37258 0 12 0s12 5.37258 12 12-5.37258 12-12 12z'/%3E%3C/svg%3E";
$icon-service-level-checkmark-svg: "data:image/svg+xml,%3Csvg version='1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath fill='%234caf50' d='M340 679l533-545c14-15 37-15 51 0l55 55c14 14 14 38 0 52L368 866c-7 8-17 11-27 11-10 1-21-3-29-11L21 569a40 40 0 0 1 0-55l52-52c15-15 40-15 55 0l212 217z'/%3E%3C/svg%3E";
$icon-transfer-man-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='24' viewBox='0 0 15 24'%3E%3Cpath fill='%233c7dfa' fill-rule='evenodd' d='M.9307 10.474l1.0396-4.3006 4.29703-1.3179 3.32673.41618 2.72817 4.27745h2.60846v1.27168H11.3267L9.8713 8.39308l-.62378 4.99422L10.8794 24H9.2475l-2.56435-8.67052L2.38615 24l-1.17823-.69364 3.39604-9.91908.83168-6.3815-2.21782.48555-.9955 3.5029L.9307 10.474zm7.7718-6.13875c-1.1953 0-2.16427-.97048-2.16427-2.16763S7.50723 0 8.70253 0s2.16426.97048 2.16426 2.16763-.969 2.16763-2.1643 2.16763z'/%3E%3C/svg%3E";
$nav-end-active-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='24' height='3' viewBox='0 0 24 2.59'%3E%3Cpath fill='%233c7dfa' fill-rule='evenodd' d='M0 0h24v2.58657H0l.89046-1.3145'/%3E%3C/svg%3E";
$nav-end-inactive-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='24' height='3' viewBox='0 0 24 2.59'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M0 0h24v2.58657H0l.89046-1.3145'/%3E%3C/svg%3E";
$nav-long-end-active-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='24' height='2.2'%3E%3Cpath fill='%233c7dfa' fill-rule='evenodd' d='M0 0h24v2.2616H0l.77858-1.14934'/%3E%3C/svg%3E";
$nav-long-end-inactive-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='24' height='2.2'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M0 0h24v2.2616H0l.77858-1.14934'/%3E%3C/svg%3E";
$nav-long-middle-active-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='24' height='2.2'%3E%3Cpath fill='%233c7dfa' fill-rule='evenodd' d='M0 0h23.2585L24 1.11226l-.7415 1.11225L0 2.2616l.77858-1.14934'/%3E%3C/svg%3E";
$nav-long-start-active-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='24' height='2.2'%3E%3Cpath fill='%233c7dfa' fill-rule='evenodd' d='M0 0h23.2585L24 1.11226l-.7415 1.11225L0 2.2616'/%3E%3C/svg%3E";
$nav-middle-active-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='24' height='3' viewBox='0 0 24 2.59'%3E%3Cpath fill='%233c7dfa' fill-rule='evenodd' d='M0 0h23.15194L24 1.27208l-.84806 1.2721L0 2.58657l.89046-1.3145'/%3E%3C/svg%3E";
$nav-middle-inactive-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='24' height='3' viewBox='0 0 24 2.59'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M0 0h23.15194L24 1.27208l-.84806 1.2721L0 2.58657l.89046-1.3145'/%3E%3C/svg%3E";
$nav-start-active-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='24' height='3' viewBox='0 0 24 2.59'%3E%3Cpath fill='%233c7dfa' fill-rule='evenodd' d='M0 0h23.15194L24 1.27208l-.84806 1.2721L0 2.58657'/%3E%3C/svg%3E";
// Base colors
$theme-bg: #073690 !default;
$theme-text: $theme-bg !default;
$theme-heading: #094180 !default;

// Text colors
$link-color: #035ab4 !default;
$text-color-dark: #222 !default;
$text-color-light: white !default;

// Button colors
$btn-color: $text-color-light !default;
$btn-bg: $theme-bg !default;
$btn-border-color: darken($btn-bg, 20%) !default;

$btn-inactive-color: $text-color-light !default;
$btn-inactive-bg: #818181 !default;
$btn-inactive-border-color: darken($btn-inactive-bg, 20%) !default;

$btn-flat-color: $btn-color !default;
$btn-flat-bg: $btn-bg !default;
$btn-flat-hover-color: $btn-flat-color !default;
$btn-flat-hover-bg: darken($btn-flat-bg, 10%) !default;

$btn-flat-secondary-color: $text-color-dark !default;
$btn-flat-secondary-bg: white !default;
$btn-flat-secondary-hover-color: $text-color-light !default;
$btn-flat-secondary-hover-bg: darken($btn-flat-secondary-bg, 39%) !default;

$btn-small-white-color: white !default;
$btn-small-white-bg: $theme-text !default;
$btn-small-white-hover-color: black !default;
$btn-small-white-hover-bg: $btn-small-white-bg !default;

$btn-small-themed-color: white !default;
$btn-small-themed-bg: $theme-text !default;
$btn-small-themed-hover-color: black !default;
$btn-small-themed-hover-bg: $btn-small-white-bg !default;

// Input colors
$outline-color: $theme-text !default;
$input-border-color: #dcdcdc !default;
$input-placeholder-color: #4a4a4a !default;

$input-disabled-color: #9b9b9b !default;
$input-disabled-bg: #fafafa !default;

$input-valid-border: #5da40b !default;
$input-invalid-border: #d0021b !default;

// Other colors
$warning-balloon-bg: white !default;
$hr-color: #979797 !default;
$noflight-switch: $text-color-light !default;
$icon-servive-level-checkmark-color: #4caf50 !default;

// Images
$logo: $asset-url + '/images/site/logo.png' !default;
$booking-bg: $asset-url + '/images/stage2/background.jpg' !default;
$completed-img: $asset-url + '/images/completed/thank-you.jpg' !default;

// Step 1
$step1-bg: $asset-url + '/images/flight/background4.jpg' !default;
$step1-mobile-bg: $asset-url + '/images/flight/background1.jpg' !default;

// SVG icons fallback
$icon-info-svg: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjM2M3ZGZhIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAyNEM1LjM3MjU4IDI0IDAgMTguNjI3NDIgMCAxMlM1LjM3MjU4IDAgMTIgMHMxMiA1LjM3MjU4IDEyIDEyLTUuMzcyNTggMTItMTIgMTJ6TTEwLjQzMjE2IDIuNzAxMzh2Mi44NjgxNWgzLjE4MzdWMi43MDEzOGgtMy4xODM3em0tMS4zOTUxIDQuNjgyNTR2Ljc5MzNoLjAwMzcyYy43MDgxIDAgMS4yOTY0NC41Nzg2NSAxLjI5NDQ1IDEuMjkyNDVsLS4wMjA3IDcuNDU4NmMtLjAwMTk3LjcwOTA3LS41MDU2NCAxLjI5MDI0LTEuMTI0OTYgMS4yODc1di43ODAyNGg1LjcwMnYtLjc2MzE2Yy0uNTg5MTggMC0xLjE3ODM3LS41ODE2LTEuMTc4MzctMS4yOTkwNlY3LjI4MzJzLTIuOTAwMzgtLjAxMDIyLTMuMzc0MzggMGMtLjQ3NC4wMTAyLTEuMzAxNzcuMTAwNzItMS4zMDE3Ny4xMDA3MnoiLz48L3N2Zz4=' !default;
$icon-minus-svg: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjM2M3ZGZhIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAyNEM1LjM3MjU4IDI0IDAgMTguNjI3NDIgMCAxMlM1LjM3MjU4IDAgMTIgMHMxMiA1LjM3MjU4IDEyIDEyLTUuMzcyNTggMTItMTIgMTJ6TTQuNSAxMC41djNoMTV2LTNoLTE1eiIvPjwvc3ZnPg==' !default;
$icon-plus-svg: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjM2M3ZGZhIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC41IDEwLjVoLTZ2M2g2djZoM3YtNmg2di0zaC02di02aC0zdjZ6TTEyIDI0QzUuMzcyNTggMjQgMCAxOC42Mjc0MiAwIDEyUzUuMzcyNTggMCAxMiAwczEyIDUuMzcyNTggMTIgMTItNS4zNzI1OCAxMi0xMiAxMnoiLz48L3N2Zz4=' !default;
$icon-transfer-man-svg: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDE1IDI0Ij48cGF0aCBmaWxsPSIjM2M3ZGZhIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uOTMwNyAxMC40NzRsMS4wMzk2LTQuMzAwNiA0LjI5NzAzLTEuMzE3OSAzLjMyNjczLjQxNjE4IDIuNzI4MTcgNC4yNzc0NWgyLjYwODQ2djEuMjcxNjhIMTEuMzI2N0w5Ljg3MTMgOC4zOTMwOGwtLjYyMzc4IDQuOTk0MjJMMTAuODc5NCAyNEg5LjI0NzVsLTIuNTY0MzUtOC42NzA1MkwyLjM4NjE1IDI0bC0xLjE3ODIzLS42OTM2NCAzLjM5NjA0LTkuOTE5MDguODMxNjgtNi4zODE1LTIuMjE3ODIuNDg1NTUtLjk5NTUgMy41MDI5TC45MzA3IDEwLjQ3NHptNy43NzE4LTYuMTM4NzVjLTEuMTk1MyAwLTIuMTY0MjctLjk3MDQ4LTIuMTY0MjctMi4xNjc2M1M3LjUwNzIzIDAgOC43MDI1MyAwczIuMTY0MjYuOTcwNDggMi4xNjQyNiAyLjE2NzYzLS45NjkgMi4xNjc2My0yLjE2NDMgMi4xNjc2M3oiLz48L3N2Zz4=' !default;
$icon-deselect-passenger-svg: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMiAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+WDwvdGl0bGU+PHBhdGggZD0iTTExLjAwNSAxMS4yNzNMMy4xODUgMi40MSAyLjgyNCAyIDIgMi43MjZsLjM2My40MTIgNy45MSA4Ljk2NS03LjkxIDguOTY1TDIgMjEuNDhsLjgyMy43MjYuMzYzLS40MTIgNy44Mi04Ljg2MiA3LjgyIDguODYyLjM2Mi40MTIuODIzLS43MjYtLjM2Mi0uNDEyLTcuOTEtOC45NjUgNy45MS04Ljk2NS4zNjMtLjQxMkwxOS4xOSAybC0uMzYzLjQxLTcuODIgOC44NjN6IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZT0iIzA5NDE4MCIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PC9zdmc+' !default;
$clear-disabled-field-svg: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMiAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+WDwvdGl0bGU+PHBhdGggZD0iTTExLjAwNSAxMS4yNzNMMy4xODUgMi40MSAyLjgyNCAyIDIgMi43MjZsLjM2My40MTIgNy45MSA4Ljk2NS03LjkxIDguOTY1TDIgMjEuNDhsLjgyMy43MjYuMzYzLS40MTIgNy44Mi04Ljg2MiA3LjgyIDguODYyLjM2Mi40MTIuODIzLS43MjYtLjM2Mi0uNDEyLTcuOTEtOC45NjUgNy45MS04Ljk2NS4zNjMtLjQxMkwxOS4xOSAybC0uMzYzLjQxLTcuODIgOC44NjN6IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZT0iIzliOWI5YiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PC9zdmc+' !default;
$icon-service-level-checkmark-svg: 'data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAwIDEwMDAiPjxwYXRoIGZpbGw9IiM0Y2FmNTAiIGQ9Ik0zNDAgNjc5bDUzMy01NDVjMTQtMTUgMzctMTUgNTEgMGw1NSA1NWMxNCAxNCAxNCAzOCAwIDUyTDM2OCA4NjZjLTcgOC0xNyAxMS0yNyAxMS0xMCAxLTIxLTMtMjktMTFMMjEgNTY5YTQwIDQwIDAgMCAxIDAtNTVsNTItNTJjMTUtMTUgNDAtMTUgNTUgMGwyMTIgMjE3eiIvPjwvc3ZnPg==' !default;

// Nav buttons
$nav-long-end-active-svg: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyLjIiPjxwYXRoIGZpbGw9IiMzYzdkZmEiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTAgMGgyNHYyLjI2MTZIMGwuNzc4NTgtMS4xNDkzNCIvPjwvc3ZnPg==' !default;
$nav-long-end-inactive-svg: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyLjIiPjxwYXRoIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTAgMGgyNHYyLjI2MTZIMGwuNzc4NTgtMS4xNDkzNCIvPjwvc3ZnPg==' !default;
$nav-long-middle-active-svg: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyLjIiPjxwYXRoIGZpbGw9IiMzYzdkZmEiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTAgMGgyMy4yNTg1TDI0IDEuMTEyMjZsLS43NDE1IDEuMTEyMjVMMCAyLjI2MTZsLjc3ODU4LTEuMTQ5MzQiLz48L3N2Zz4=' !default;
$nav-long-start-active-svg: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyLjIiPjxwYXRoIGZpbGw9IiMzYzdkZmEiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTAgMGgyMy4yNTg1TDI0IDEuMTEyMjZsLS43NDE1IDEuMTEyMjVMMCAyLjI2MTYiLz48L3N2Zz4=' !default;

// Nav buttons return
$nav-end-active-svg: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIzIiB2aWV3Qm94PSIwIDAgMjQgMi41OSI+PHBhdGggZmlsbD0iIzNjN2RmYSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDI0djIuNTg2NTdIMGwuODkwNDYtMS4zMTQ1Ii8+PC9zdmc+' !default;
$nav-end-inactive-svg: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIzIiB2aWV3Qm94PSIwIDAgMjQgMi41OSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDI0djIuNTg2NTdIMGwuODkwNDYtMS4zMTQ1Ii8+PC9zdmc+' !default;
$nav-middle-active-svg: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIzIiB2aWV3Qm94PSIwIDAgMjQgMi41OSI+PHBhdGggZmlsbD0iIzNjN2RmYSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDIzLjE1MTk0TDI0IDEuMjcyMDhsLS44NDgwNiAxLjI3MjFMMCAyLjU4NjU3bC44OTA0Ni0xLjMxNDUiLz48L3N2Zz4=' !default;
$nav-middle-inactive-svg: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIzIiB2aWV3Qm94PSIwIDAgMjQgMi41OSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDIzLjE1MTk0TDI0IDEuMjcyMDhsLS44NDgwNiAxLjI3MjFMMCAyLjU4NjU3bC44OTA0Ni0xLjMxNDUiLz48L3N2Zz4=' !default;
$nav-start-active-svg: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSIyNCIgaGVpZ2h0PSIzIiB2aWV3Qm94PSIwIDAgMjQgMi41OSI+PHBhdGggZmlsbD0iIzNjN2RmYSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDIzLjE1MTk0TDI0IDEuMjcyMDhsLS44NDgwNiAxLjI3MjFMMCAyLjU4NjU3Ii8+PC9zdmc+' !default;
