@import '../../includes/all';

$enabled-bg: $theme-bg;
$disabled-bg: #ccc;
$handle-bg: white;

.switch {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 14px;
  margin-right: 5px; // Page specific

  input {
    display: none;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $disabled-bg;
  transition: .4s;

  &::before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: -1px;
    bottom: -1px;
    background-color: $handle-bg;
    transition: .4s;

    @include mobile {
      border: 1px solid $disabled-bg;
    }
  }

  &.round {
    border-radius: 14px;

    &::before {
      border-radius: 50%;
    }
  }
}

input {
  &:not(:checked) + .slider {
    background-color: $enabled-bg;

    &::before {
      transform: translateX(18px);
      border-color: $enabled-bg;
    }
  }

  &:focus + .slider {
    box-shadow: 0 0 1px $enabled-bg;
  }
}

// Page-specific styling
.switch-container, 
.noflight-label {
  vertical-align: middle;
}

#flight-info-btn {
  height: 2.5em;
  margin-left: 1em;
  vertical-align: middle;
  cursor: pointer;

  &:hover + #flight-info-content {
    opacity: 1;
  }
}

.noflight-label > strong {
  font-size: 1.2em;
  color: $noflight-switch;
  font-weight: bold;
}

#flight-info {
  display: inline-block;
}

#flight-info-content {
  opacity: 0;
  transition: opacity 1s;
  z-index: 100;
  min-width: 440px;
  
  position: absolute;
  bottom: calc(100% + 30px);
  background-color: rgba($theme-bg, .9);
  left: 0;
  right: 50%;
  padding: 10px 20px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, .3);
  border-radius: 5px;

  &.showing {
    opacity: 1;
  }

  > ol {
    margin: 0;
    padding: 0;
    counter-reset: steps;

    > li {
      display: inline-block;
      vertical-align: top;
      width: (100% / 3);
      padding-right: 1em;

      > span, &::before {
        display: table-cell;
      }

      &::before {
        counter-increment: steps;                 /* Increment the value of section counter by 1 */
        content: counter(steps) '.';
        padding-right: .5em; 
        font-weight: bold;
      }
    }
  }
}

#flight-info-heading {
  font-weight: 600;
  margin-bottom: 15px;
}

#flight-enabled {
  display: none;
}
