@import '../../includes/all';

$browser-upgrade-bg: #f2dede;
$browser-upgrade-color: #a94441;
$browser-upgrade-border: #ebccd1;

select,
input:not([type='checkbox']) {
  height: 33px;

  &[disabled] {
    background-color: $input-disabled-bg !important;
    color: $input-disabled-color;
  }
}

button:not(.custom-btn):not([data-toggle='dropdown']):not(.btn-primary),
.btn:not([data-action]) {
  border: 0;

  &:not(.small-white):not(.small-blue):not(.simple) {
    height: 30px;
    font-size: 16px;
    background-color: $btn-bg;
    border-bottom: 2px solid $btn-border-color;
    color: $btn-color;
    padding: 1px 6px;

    @media screen and (min-device-width: $medium) {
      &:hover {
        border-bottom-width: 1px;
        padding-top: 2px;
        vertical-align: top;

         > img {
          top: 7px !important;
        }
      }
    }
  }

  &.simple {
    border-radius: 0;
    background-color: $btn-flat-bg;
    color: $btn-flat-color;
    width: 125px;
    height: 35px;
    border: 0;
    font-size: 16px;

    &:hover {
      color: $btn-flat-hover-color;
      background-color: $btn-flat-hover-bg;
    }

    &.white {
      background-color: $btn-flat-secondary-bg;
      color: $btn-flat-secondary-color;

      &:hover {
        color: $btn-flat-secondary-hover-color;
        background-color: $btn-flat-secondary-hover-bg;
      }
    }
  }

  &.small-white,
  &.small-blue {
    padding: 3px 7px;
    font-size: 14px;
    border-radius: 3px;
    vertical-align: middle;
    line-height: normal;
  }

  &.small-white {
    color: $btn-small-white-color;
    background-color: $btn-small-white-bg;

    &:hover {
      color: $btn-small-white-hover-color;
      background-color: $btn-small-white-hover-bg;
    }
  }

  &.small-blue {
    color: $btn-small-themed-color;
    background-color: $btn-small-themed-bg;

    &:hover {
      color: $btn-small-themed-hover-color;
      background-color: $btn-small-themed-hover-bg;
    }
  }
}

body {
  height: 100%;
  font-size: 14px;
}

* {
  @include font-regular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-light;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 40px;

  &,
  & span {
    @include font-light;
  }
}

h3 {
  font-size: 20px;
  margin: 15px 0 5px;
}

h4 {
  margin: 0;
  font-size: 20px;
}

a {
  text-decoration: none;

  &[href] {
    color: $link-color;
  }
}

input,
select,
button {
  outline-color: $outline-color;
}

select {
  font-size: 12px;
  padding: 0 0 0 5px;
}

input {
  &[type='text'],
  &[type='password'],
  &[type='email'],
  &[type='tel'] {
    padding: 0 0 0 10px;
  }
}

.freshwidget-button {
  display: none !important;

   > a {
    border-radius: 0 !important;
    color: $btn-color !important;
    background-color: $btn-bg !important;
    font-weight: normal !important;
  }
}

#browserupgrade {
  margin: 0;
  background: $browser-upgrade-bg;
  color: $browser-upgrade-color;
  border: 1px solid $browser-upgrade-border;
  padding: 5px;
  text-align: center;
  font-size: 40px;
}

// Placeholders
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: $input-placeholder-color;
  opacity: 1;
}
