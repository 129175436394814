@import '../../includes/all';

@include mobile {
  .desktop {
    display: none !important;
  }

  #mainForm {
    max-width: 450px;
    margin: auto;
  }

  html {
    height: 100%;
  }
}

@include desktop {
  #mainForm {
    margin: 0 auto;
    max-width: 990px;
    padding: 15px;
    padding-bottom: 40px;
    min-height: 220px;
    transition: padding-bottom .4s;
  }
}

#plugin-container {
  text-align: left;
  height: auto;

  &,
  & button {
    font-size: 16px;

    @include gte($tablet) {
      font-size: 14px;
    }
  }
}

#message-container {
  display: block;
  z-index: 10;
  width: 100%;

  @include mobile {
    display: block;
    border-radius: 3px;
    overflow: hidden;

    &.full-height {
      height: 100%;
    }

    &.focused {
      z-index: 100;
    }
  }

  @include desktop {
    position: relative;
    top: auto;
    bottom: 0;
    margin: 0 auto;
    max-width: 990px;
    padding: 10px 15px;

    #errorContainer > div {
      border-radius: 5px;
      width: auto;
    }
  }
}
