@import '../includes/all';

$autocomplete-color: #646464;
$autocomplete-bg: white;
$autocomplete-bg-active: $theme-bg;
$autocomplete-border: lightgrey;

$icon-color: $theme-text;

.ac-results {
  position: absolute;
  background-color: $autocomplete-bg;
  z-index: 10;
  width: 100%;
  overflow: hidden;

  &:not(:empty) {
    border-top: 1px solid $autocomplete-border;

    @include desktop {
      border: 1px solid $autocomplete-border;
    }
  }

  @include desktop {
    width: auto;
    min-width: 100%;
    border-top: 0;
  }

   > div:not(.ac-seperator):not(.no-results-msg) {
    color: $autocomplete-color;
    cursor: pointer;
    white-space: nowrap;

    &:not(.ac-custom-result) {
      padding: 5px 10px;
      height: 30px;
    }

    &:hover {
      color: $text-color-dark;
      background-color: rgba(0,0,0,.02);
    }

    &.active {
      color: $text-color-light;
      background-color: $autocomplete-bg-active;

       > .ac-result-icon {
        color: $text-color-light;
      }
    }
  }
}

.ac-result-icon {
  display: inline-block;
  height: 30px;
  width: 30px;
  vertical-align: middle;
  text-align: center;
  color: $icon-color;
  position: relative;

  .fa {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    font-size: 14px;
  }
}

.ac-result-text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 8px 0 0;
}

.ac-seperator {
  font-size: 16px;
  padding: 5px 10px;
  color: $theme-text;
  font-weight: 700;
}

.no-results-msg {
  color: $autocomplete-color;
  padding: 5px 10px;
  height: 30px;
}
