@import '../../includes/all';

#modeSelects {
  display: none;

  &.shown {
    display: inline-block;
  }

  > button {
    height: auto;
    width: auto;
    padding: 3px 13px;
    text-align: left;
    font-size: 14px;
    color: $btn-inactive-color;
    background-color: $btn-inactive-bg;
    border-bottom-color: $btn-inactive-border-color;

    &.geselecteerd {
      color: $btn-color;
      background-color: $btn-bg;
      border-bottom-color: $btn-border-color;
      cursor: default;
    }

    &:not(:last-child) {
      margin-right: 2%;
    }

    .mode-img {
      max-width: 100%;
      max-height: 20px;
      margin-bottom: 3px;

      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 90px;
    }
  }

  @include mobile {
    // Remove this for three buttons
    display: block;

    > button {
      width: 47%;
      margin: 0;
      float: right;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .2);

      &:first-child {
        float: left;
      }
    }
  }

  @include desktop {
    text-align: left;
    margin: 0;
    min-width: 110px;

    > button {
      min-height: 28px;
      float: left;

      &:not(:last-child) {
        margin-right: 15px;
      }

      &:not(.geselecteerd):hover {
        transform: translateY(1px);
      }

      &.geselecteerd {
        border-bottom-width: 1px;
        margin-top: 1px;
      }
    }
  }
}

#mode1 {
  // Door to door
  min-width: 180px !important;

  > div {
    display: inline-block;
    vertical-align: middle;

    &:nth-child(2) {
      font-size: 20px;
      margin: -1em 7px;
    }
  }
}
