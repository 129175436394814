.trip-options {
  color: $text-color-light;
  margin-bottom: 5px;

  @include desktop {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.noflight-checkbox {
  vertical-align: middle;
}

.noflight-btn {
  width: auto !important;
  padding: 3px 7px !important;
  height: auto !important;
  vertical-align: middle;
}

.noflight-btn-container {
  font-size: 14px;
  width: 100%;
  position: relative;

  @include mobile {
    font-size: 12px;
    background-color: white;
    color: $text-color-dark;
    display: table;
    padding: 10px !important;

     > .switch-container,
     > label {
      display: table-cell;
      vertical-align: middle;
    }

     > .switch-container {
      padding: 0 10px;
    }
  }

  @include desktop {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
